import React from 'react';
import QrCodeIcon from '@mui/icons-material/QrCode';

const QrCodeIconComponent = React.forwardRef((props, ref) => (
  <div ref={ref} style={{ width: '32px', height: '32px' }}>
    <QrCodeIcon style={{ width: '100%', height: '100%', color: '#1976d2' }} />
  </div>
));

export default QrCodeIconComponent;
