// Header.js
import React from 'react';
import './Header.css';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Box, Typography } from '@mui/material';

const Header = () => {
    return (
        <header className="App-header">
            <Box display="flex" alignItems="center">
                <QrCodeIcon sx={{ fontSize: 40, marginRight: 2 }} />
                <Typography variant="h4">DVIC/VSA QR Code Generator</Typography>
            </Box>
        </header>
    );
}

export default Header;
