import React, { useCallback } from 'react';
import { Box, Typography, styled, Snackbar } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const UploadBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '10rem',
    border: '2px dashed',
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[50],
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.grey[100],
        borderColor: theme.palette.grey[400],
    },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function UploadButton({ handleFileUpload }) {
    const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'info' });

    const onDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files[0];
        if (file) {
            handleFile(file);
        }
    }, [handleFileUpload]);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    const onChange = useCallback((event) => {
        const file = event.target.files[0];
        if (file) {
            handleFile(file);
        }
    }, [handleFileUpload]);

    const handleFile = (file) => {
        if (!file) return;

        // Check file size (e.g., max 5MB)
        if (file.size > 5 * 1024 * 1024) {
            setSnackbar({ open: true, message: 'File size exceeds 5MB limit', severity: 'error' });
            return;
        }

        // Check file type
        if (!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type)) {
            setSnackbar({ open: true, message: 'Invalid file type. Please upload an Excel file.', severity: 'error' });
            return;
        }

        handleFileUpload(file);
    };

    return (
        <>
            <label htmlFor="upload-excel-file">
                <UploadBox
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                >
                    <CloudUploadIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                    <Typography variant="body1" color="text.secondary" align="center" sx={{ mb: 1 }}>
                        <Box component="span" fontWeight="fontWeightBold">Click to upload</Box> or drag and drop
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        Excel files only (.xlsx, .xls)
                    </Typography>
                </UploadBox>
                <VisuallyHiddenInput
                    id="upload-excel-file"
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={onChange}
                />
            </label>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                severity={snackbar.severity}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            />
        </>
    );
}

export default UploadButton;
